var data;

data = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
  },
  {
    id: 2,
    label: "menuitems.dashboard.text",
    icon: "uil-home-alt",
    badge: {
      variant: "primary",
      text: "menuitems.dashboard.badge",
    },
    link: "/",
  },
  {
    id: 3,
    label: "menuitems.apps.text",
    isTitle: true,
  },
  // {
  //   id: 6,
  //   label: "Properties",
  //   icon: "fas fa-cogs",
  //   link: "/properties",
  // },
  {
    id: 9,
    label: "Landing Page",
    icon: "fas fa-edit",
    link: "/landing-page",
  },
  // {
  //   id: 10,
  //   label: "Corporate",
  //   icon: "fas fa-building",
  //   link: "/corporate",
  // },
  // {
  //   id: 11,
  //   label: "Career",
  //   icon: "fas fa-handshake",
  //   link: "/career",
  // },
  // {
  //   id: 12,
  //   label: "Help Center",
  //   icon: "fas fa-question",
  //   link: "/help-center",
  // },
  // {
  //   id: 13,
  //   label: "Guides",
  //   icon: "fas fa-book-medical",
  //   link: "/guides",
  // },
  // {
  //   id: 15,
  //   label: "Forms",
  //   icon: "mdi mdi-format-float-left",
  //   link: "/forms",
  // },
  // {
  //   id: 16,
  //   label: "Definitions",
  //   icon: "fas fa-cogs",
  //   link: "/definitions",
  // },
  {
    id: 18,
    label: "Onay",
    icon: "fas fa-tasks",
    link: "/onay",
  },
  {
    id: 19,
    label: "User",
    icon: "fas fa-users",
    link: "/user",
  },
  {
    id: 20,
    label: "Leads",
    icon: "fas fa-bookmark",
    link: "/lead",
  },
];

export const menuItems = data;
